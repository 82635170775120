<template>
  <chemical-isotope :symbol="symbol" v-bind="$attrs" :style="style" v-on="$listeners" />
</template>

<script>
import LegacyChemicalElementValue from '../../task-values/models/legacy/LegacyChemicalElementValue';
import ChemicalIsotope from '@/tasks/components/displayers/ChemicalIsotope';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import {useRandomNumberBackgroundStyle} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'ChemicalElementValue',
  components: {LatexNumber, ChemicalIsotope},
  props: {
    value: {
      type: LegacyChemicalElementValue,
      required: true,
    },
  },
  setup() {
    return useRandomNumberBackgroundStyle();
  },
  computed: {
    symbol() {
      return this.value.symbol;
    },
  },
};
</script>
