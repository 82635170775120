<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Determine the ground state electron configuration for
        <chemical-element-value :value="chemicalElement" />
      </p>
      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="6"
        :max-occupation="14"
        :orbital-types="['s', 'p', 'd', 'f']"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import ElectronConfigurationInput from '@/tasks/components/inputs/ElectronConfigurationInput.vue';
import ChemicalElementValue from '@/tasks/components/ChemicalElementValue.vue';

export default {
  name: 'ElectronConfigTransitionMetal',
  components: {ChemicalElementValue, ElectronConfigurationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('transitionMetal').content;
    },
  },
};
</script>

<style scoped></style>
